import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "../../app/context/useSnackbar";
import { apiRequest } from "../../app/services/requests";

const FileUploader = (props: any) => {
  const { openSnackbar } = useSnackbar();

  const { displayType, uploadType } = props;

  const [selectedFile, setSelectedFile] = useState<any>("");

  const onInputClick = (event: any) => {
    const element = event.target;
    element.value = "";
  };

  function validFileType(fileList: any) {
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const invalidExtension = fileList?.some(
      (file: any) =>
        !allowedExtensions.includes(file?.name?.split(".").pop()?.toLowerCase())
    );
    if (invalidExtension) {
      openSnackbar("Only JPEG and PNG files are allowed");
      return;
    }
    return true;
  }

  const handleChange = (event: any) => {
    if (!event?.target.files || event?.target?.files?.length === 0) {
      setSelectedFile("") as any;
      return;
    }
    if (event?.target?.files?.length) {
      try {
        const fileList = Array.from(event.target.files);

        if (validFileType(fileList)) {
          const maxSize = 1024 * 1024 * 50;
          const totalSize = fileList?.reduce(
            (total, file: any) => total + file.size,
            0
          ) as any;
          if (totalSize > maxSize) {
            openSnackbar("All File size exceeds the maximum limit (50MB)");
            event.target.value = "";
            return;
          } else {
            setSelectedFile(fileList as any);
            uploadFileToS3(fileList[0]);
          }
        } else {
          openSnackbar("Max file size should be less than 50 MB");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const uploadFileToS3 = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    apiRequest(
      {
        url: `upload`,
        data: formData,
        method: "POST",
      },
      {
        isFileUpload: true,
      }
    )
      .then((res) => {
        if (displayType === "aadharCard") {
          const updatedUploadIdImages = {
            ...props?.uploadAadharCard,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setUploadAadharCard(
            updatedUploadIdImages?.aadharCardResponseFiles
          );
        } else if (displayType === "panCard") {
          const updatedUploadIdImages = {
            ...props?.uploadPanCard,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setUploadPanCard(updatedUploadIdImages?.panCardResponseFiles);
        } else if (displayType === "educationCertificates") {
          const updatedUploadIdImages = {
            ...props?.uploadEducationCertificates,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setUploadEducationCertificates(
            (prevUploadEducationCertificates: any) => ({
              ...prevUploadEducationCertificates,
              educationCertificatesResponseFiles: [
                ...(prevUploadEducationCertificates?.educationCertificatesResponseFiles ||
                  []),
                updatedUploadIdImages?.educationCertificatesResponseFiles,
              ],
            })
          );
        } else if (displayType === "workExperienceCertificates") {
          const updatedUploadIdImages = {
            ...props?.workExperienceCertificates,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setWorkExperienceCertificates(
            (prevWorkExperienceCertificates: any) => ({
              ...prevWorkExperienceCertificates,
              workExperienceCertificatesResponseFiles: [
                ...(prevWorkExperienceCertificates?.workExperienceCertificatesResponseFiles ||
                  []),
                updatedUploadIdImages?.workExperienceCertificatesResponseFiles,
              ],
            })
          );
        } else if (displayType === "achievementCertificates") {
          const updatedUploadIdImages = {
            ...props?.achievementCertificates,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setAchievementCertificates(
            (prevAchievementCertificates: any) => ({
              ...prevAchievementCertificates,
              achievementCertificatesResponseFiles: [
                ...(prevAchievementCertificates?.achievementCertificatesResponseFiles ||
                  []),
                updatedUploadIdImages?.achievementCertificatesResponseFiles,
              ],
            })
          );
        } else if (displayType === "additionalCertificates") {
          const updatedUploadIdImages = {
            ...props?.additionalCertificates,
            [`${uploadType}ResponseFiles`]: res,
          };
          props.setAdditionalCertificates(
            (prevAdditionalCertificates: any) => ({
              ...prevAdditionalCertificates,
              additionalCertificatesResponseFiles: [
                ...(prevAdditionalCertificates?.additionalCertificatesResponseFiles ||
                  []),
                updatedUploadIdImages?.additionalCertificatesResponseFiles,
              ],
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = () => {
    const uploadType = props?.uploadType as string;
    if (uploadType) {
      document.getElementById(uploadType)?.click();
    }
  };

  return (
    <>
      {displayType === "aadharCard" && (
        <>
          <Box
            sx={{
              cursor: "pointer",
            }}
            color={"#5C4EFF"}
            border={"1px dotted #5C4EFF"}
            textAlign={"center"}
            padding={"11px 10px"}
            onClick={handleClick}
            borderRadius={"8px"}
          >
            <Typography
              fontSize={"12px"}
              sx={{
                textDecoration: "underLine",
              }}
            >
              {props.uploadText}
            </Typography>
          </Box>
        </>
      )}

      {displayType === "panCard" && (
        <>
          <Box
            sx={{
              cursor: "pointer",
            }}
            color={"#5C4EFF"}
            border={"1px dotted #5C4EFF"}
            textAlign={"center"}
            padding={"11px 10px"}
            onClick={handleClick}
            borderRadius={"8px"}
          >
            <Typography
              fontSize={"12px"}
              sx={{
                textDecoration: "underLine",
              }}
            >
              {props.uploadText}
            </Typography>
          </Box>
        </>
      )}

      {(displayType === "educationCertificates" ||
        displayType === "workExperienceCertificates" ||
        displayType === "achievementCertificates" ||
        displayType === "additionalCertificates") && (
        <>
          <Box
            sx={{
              cursor: "pointer",
            }}
            color={"#5C4EFF"}
            border={"1px dotted #5C4EFF"}
            textAlign={"center"}
            padding={"11px 10px"}
            onClick={handleClick}
            borderRadius={"8px"}
          >
            <Typography
              fontSize={"12px"}
              sx={{
                textDecoration: "underLine",
              }}
            >
              {props.uploadText}
            </Typography>
          </Box>
        </>
      )}

      <input
        id={`${props?.uploadType}`}
        name={props?.uploadType}
        key={`${props?.uploadType ?? ""}-uploaded-key-${selectedFile?.Key}`}
        type="file"
        onChange={(event) => handleChange(event)}
        accept={props?.accept?.[0]}
        onClick={onInputClick}
        style={{ display: "none" }}
        multiple={false} //props.multiple
        defaultValue={props.defaultValue}
      />
    </>
  );
};

export default FileUploader;
