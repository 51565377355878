import { Box, Grid, Typography } from "@mui/material";
import CustomButton from "../../../components/Button";
import { theme } from "../../../theme/theme";

import { useEffect, useState } from "react";
import { PaymentSuccessScreen } from "../components/payment-success";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../../app/services/requests";
import { useSnackbar } from "../../../app/context/useSnackbar";

declare global {
  interface Window {
    Razorpay: any;
  }
}

const Step3 = (props: any) => {
  const { onClickBack } = props;
  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { clientId, planId } = useParams<any>();

  const [clientDetails, setClientDetails] = useState("") as any;

  const [showPaymentSuccessScreen, setShowPaymentSuccessScreen] =
    useState<any>(false);

  const [orderDetails, setOrderDetails] = useState<any>({});

  const [orderSuccess, setOrderSuccess] = useState({});

  const [loaderForOrderDetails, setLoaderForOrderDetails] = useState(false);

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  function loadScript(src?: any) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      // openSnackbar("Razorpay SDK failed to load. Are you online?", '');
      return;
    }

    const { payablePrice = 2000, currency = "INR", payment } = orderDetails;

    console.log(
      payablePrice,
      currency,
      payment,
      orderDetails,
      "RRRRRRRRRRRRRRRRRRRRRRR"
    );

    const options = {
      key: "rzp_test_sn3dHJMzFiJljo", // Enter the Key ID generated from the Dashboard
      amount: payablePrice * 100,
      currency: currency,
      name: "Dentassure",
      description: "Test Transaction",
      logo: { logo: require("../../../app/assets/logo.png") },
      order_id: payment?.orderId,

      handler: async function (paymentResponse?: any) {
        console.log(paymentResponse, "PPPPPPPAAAAAAAAAYYYYYYYYY");
        const data = {
          paymentGatewayOrderId: orderDetails?.payment?.orderId,
          paymentId: paymentResponse.razorpay_payment_id,
          // ...(useWalletPoints === true && {
          //   applyWallet: useWalletPoints,
          // }),
        };

        apiRequest({
          url: `order/${orderDetails?._id}/update-status`,
          data: { ...data },
          method: "PUT",
        })
          .then((res) => {
            console.log(res, "PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
            if (res?.status === "success") {
              openSnackbar("your payment is success", "success");
              setOrderSuccess(res);
              setTimeout(() => {
                navigate("/dashboard");
              }, 8000);
            } else {
              openSnackbar("Payment failed", "error");
            }
          })
          .catch((err) => {
            openSnackbar(err?.response?.data?.message, "error");
          });
      },
      prefill: {
        name: `${clientDetails?.firstName} ${clientDetails?.lastName}`,
        email: clientDetails?.email ?? "",
        contact: clientDetails?.mobile,
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <>
      <Box p={2}>
        {showPaymentSuccessScreen ? (
          <PaymentSuccessScreen />
        ) : (
          <Box p={"0px 20px 50px 20px"} mt={"10px"}>
            <Typography
              mt={"10px"}
              variant="h6"
              color={theme.palette.customColors.blackGrey}
              fontWeight={"600"}
              fontSize={"16px"}
            >
              Plan Details:
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
              <Typography variant="body2">
                Plan Name : <b>Simple Plan</b>
              </Typography>
              <Typography variant="body2">
                Plan Range : <b>Family</b>
              </Typography>
              <Typography variant="body2">
                Plan Period : <b>One Year</b>
              </Typography>
            </Box>
          </Box>
        )}

        {showPaymentSuccessScreen === false && (
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={4}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={() =>
                  navigate(`/client/${clientId}/plan/standard/2/${planId}`)
                }
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="submit"
                title={"Pay Now"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                handleButtonClick={displayRazorpay}
                width={110}
                padding={7}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Step3;
