import { useState, useEffect } from "react";
import { Container, Grid, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import TreatmentList from "./treatment-list";
import SecondaryCustomTabs from "../../../components/SecondaryCustomTabs";
import CustomButton from "../../../components/Button";
import CustomDialog from "../../../components/CustomDialog";
import AllServiceList from "./all-service-list";

const DentalServices = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [fetchServicesAgain, setFetchServicesAgain] = useState(false);
  const [selectedServices, setSelectedServices] = useState<any[]>([]);
  const navigator = useNavigate();
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const { hospitalId } = useParams<any>();

  const handleSelectedTreatment = (data: any) => {
    setSelectedServices(data);
  };

  const tabs = [
    {
      index: 0,
      id: "all-services",
      name: "All Services",
      component: (
        <AllServiceList
          tabName="all-services"
          fetchServicesAgain={fetchServicesAgain}
          onSelectedTreatments={handleSelectedTreatment}
        />
      ),
    },
    {
      index: 1,
      id: "selected-services",
      name: "Selected Services",
      component: (
        <AllServiceList
          tabName="selected-services"
          fetchServicesAgain={fetchServicesAgain}
          onSelectedTreatments={handleSelectedTreatment}
        />
      ),
    },
  ];

  const handleAddDoctorClick = () => {
    setOpenServiceDialog(true);
  };

  const handleClose = (flag: boolean) => {
    setOpenServiceDialog(false);
    if (!flag) {
      navigator(`/hospital/${hospitalId}/profile/dental-services`);
    }
    // Trigger re-fetch and clear selections
    setFetchServicesAgain(true);
    setSelectedServices([]);
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "0 !important" }}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box position="relative">
              <Box position="absolute" right={0} top={10} zIndex={99} mr={2}>
                <CustomButton
                  bgColor="rgba(92, 78, 255, 1)"
                  onClick={() => {
                    handleAddDoctorClick();
                    setFetchServicesAgain(false);
                  }}
                  title={
                    selectedTab === 0 ? "Assign Services" : "Unassign Services"
                  }
                  disabled={!selectedServices.length}
                />
              </Box>

              <SecondaryCustomTabs
                tabOptions={tabs}
                currentTab={selectedTab}
                isIndexRequired
                onTabChange={(value: any) => {
                  setSelectedTab(value);
                  setFetchServicesAgain(false);
                }}
              />
            </Box>
          </Grid>
        </Grid>

        <CustomDialog
          width="850px"
          open={openServiceDialog}
          handleClose={() => handleClose(true)}
          title={
            selectedTab === 0 ? "Assign Treatments" : "Unassign Treatments"
          }
          maxWidth="lg"
        >
          <Box>
            <TreatmentList
              selectedServices={selectedServices}
              onCancel={handleClose}
              selectedTab={selectedTab}
            />
          </Box>
        </CustomDialog>
      </Box>
    </Container>
  );
};

export default DentalServices;
