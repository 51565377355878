import { Box, Grid, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { FileUploader } from "react-drag-drop-files";
import cogoToast from "cogo-toast";
import { useAppContext } from "../../app/context/AppContext";
import { FILE_PATHS, URLS } from "../../app/utilities/constants";
// import uploadToS3, { downloadFileFromS3 } from "../../app/utilities/uploadToS3";
import CustomButton from "../../components/Button";
import { styles } from "./styles";
import CustomSelectPicker from "../../components/SelectPicker";
import CustomAutocomplete from "../../components/Autocomplete";
import * as yup from "yup";

import {
  ALLERGIES,
  DRUG_HISTORY,
  FAMILY_ILLNESS,
  HABITS,
  ILLNESS,
  MEDICAL_INFORMATION,
  USING_MEDICATIONS,
} from "../../app/utilities/staticData";
import CustomInput from "../../components/Input";
import CustomDatePicker from "../../components/DatePicker";
import { ProfilePic } from "../../app/utilities";
import { theme } from "../../theme/theme";
import { apiRequest } from "../../app/services/requests";

const defaultPrevTreatment = {
  treatmentName: "",
  treatmentMonthYear: "",
};

const defaultClinicalHistoryDetails = {
  beneficiaryName: "",
  chiefComplaints: [],
  presentIllness: [],
  pastIllness: [],
  familyHistoryIllness: [],
  drugHistory: [],
  medication: [],
  allergies: [],
  medicalInformation: [],
  habits: [],
  previousTreatments: [defaultPrevTreatment],
};

const CaseHistoryForm = (props: any) => {
  const {
    onStep2Submit,
    showSkip,
    onClickBack,
    handleCancel,
    appointmentId,
    treatments,
    selectedPlanType,
  } = props;

  const navigate = useNavigate();

  const { patientId } = useParams();

  const { setLoader }: any = useAppContext();
  const { CASE_HISTORY_APPOINTMENT_URL, GET_COURSE_MASTER_URL } = URLS;

  const { PATIENTS_PATH } = FILE_PATHS;

  const [tabVal, setTabVal] = useState("photo");

  const [chiefComplaintsData, setChiefComplaintsData] = useState([]);
  const [caseHistoryData, setCaseHistoryData] = useState([]);

  let defaultDocs = [
    {
      documentName: "007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      documentType: "photo",
      documentPath:
        "patients/1706003178729-007a6f8e-8251-4079-aee8-ed95d0748a56-images.jpeg",
      fileType: "image/jpeg",
    },
  ];

  const [medicalRecords, setMedicalRecords] = useState<any>(defaultDocs);

  const patientSchema = object({
    beneficiaryName: string().required("Beneficiary Name is required"),
    chiefComplaints: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string(),
          name: yup.string(),
        })
      )
      .min(1, "At least one chiefComplaints  is required"),
    presentIllness: array()
      .min(1, "Present Illness is required")
      .required("Present Illness is required"),
    pastIllness: array()
      .min(1, "Past Illness is required")
      .required("Past Illness is required"),
    // familyHistoryIllness: array().min(1, "Family history Illness is required").required("Family history Illness is required"),
    // drugHistory: array().min(1, "Drug history is required").required("Drug history is required"),
    // medication: array().min(1, "Medication is required").required("Medication is required"),
    // allergies: array().min(1, "Allergies are required").required("Allergies are required"),
    // medicalInformation: array().min(1, "MedicalInformation is required").required("Medical Information is required"),
    // habits: array().min(1, "Habits are required").required("Habits are required"),
  } as any);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { isValid: clinicalHistoryFormValid },
  } = useForm({
    resolver: yupResolver(patientSchema),
    defaultValues: defaultClinicalHistoryDetails,
  });

  watch(["previousTreatments"]);

  const { previousTreatments } = getValues();

  const onSubmit = (data: any) => {
    // if (!clinicalHistoryFormValid) return false;
    // const finalPayload = {
    //   ...data,
    //   underMedication: data?.medication,
    //   previousDentalTreatments: previousTreatments,
    // };
    // props.onStep2Submit(finalPayload);

    const payload = {
      ...data,
      clientId: Number(patientId),
      planId: 2,
    };
  };

  const addFamilyMembers = () => {
    const { previousTreatments = [] }: any = getValues();
    const modifiedDetails: any = [...previousTreatments, defaultPrevTreatment];
    setValue("previousTreatments", modifiedDetails, {
      shouldValidate: true,
    });
  };

  const removeFamilyMembers = (index: any) => {
    const { previousTreatments = [] }: any = getValues();
    const filteredData = previousTreatments.filter(
      (previous: any, i: any) => i !== index
    );
    setValue("previousTreatments", filteredData, {
      shouldValidate: true,
    });
  };

  const handleFileChange = (file: any) => {
    // setLoader(true);
    // uploadToS3(PATIENTS_PATH, file, tabVal)
    //   .then(async (response: any) => {
    //     const files: any = response;
    //     setMedicalRecords([...medicalRecords, ...files]);
    //     // setOpenModal(false);
    //     cogoToast.success("Files uploaded successfully", {
    //       position: "top-right",
    //       heading: "Success",
    //       hideAfter: 7,
    //     });
    //     // setLoader(false);
    //   })
    //   .catch(() => {
    //     // setLoader(false);
    //     cogoToast.error("Failed to upload file. Please try again", {
    //       position: "top-right",
    //       heading: "Error",
    //       hideAfter: 7,
    //     });
    //   });
  };

  const deletePhotoDoc = async (data: any) => {
    const filtered = medicalRecords.filter(
      (photoDoc: any) => photoDoc.documentPath !== data.documentPath
    );
    setMedicalRecords(filtered);
    // if (handleUpdate) {
    //   // setLoader(true);
    //   await handleUpdate(filtered);
    //   // setLoader(false);
    // }
  };

  const removeImage = (indexToRemove: number) => {
    setMedicalRecords((prevStepDetails: any) => {
      const updatedLabWorkPhotos = prevStepDetails?.filter(
        (_: any, index: number) => index !== indexToRemove
      );

      return {
        ...prevStepDetails,
        ...updatedLabWorkPhotos,
      };
    });
  };

  return (
    <Box p={2}>
      {/* Your Basic Member Details Form or Component */}

      {selectedPlanType === "simple" ? (
        <>
          <Box p={"0px 20px 50px 20px"} mt={"10px"}>
            <Typography mt={"10px"}>
              {" "}
              {"No Case History required Confirm your plan details"}
            </Typography>
            <Typography
              mt={"10px"}
              variant="h6"
              color={theme.palette.customColors.blackGrey}
              fontWeight={"600"}
              fontSize={"16px"}
            >
              Plan Details:
            </Typography>
            <Box display={"flex"} justifyContent={"space-between"} mt={"20px"}>
              <Typography variant="body2">
                Plan Name : <b>Simple Plan</b>
              </Typography>
              <Typography variant="body2">
                Plan Range : <b>Family</b>
              </Typography>
              <Typography variant="body2">
                Plan Period : <b>One Year</b>
              </Typography>
            </Box>
            <Box mt={"20px"}>
              <Typography
                variant="h6"
                color={theme.palette.customColors.blackGrey}
                fontWeight={"600"}
                fontSize={"16px"}
              >
                Family Details :
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">
                  Spouse Name : <b>Nick</b>
                </Typography>
                <Typography variant="body2">
                  Spouse Date of Birth : <b>12 feb 2023</b>
                </Typography>
                <Typography variant="body2">
                  Gender : <b>Female</b>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">
                  Spouse Relation : <b>Son</b>
                </Typography>
              </Box>
            </Box>
            <Box mt={"20px"}>
              <Typography
                variant="h6"
                color={theme.palette.customColors.blackGrey}
                fontWeight={"600"}
                fontSize={"16px"}
              >
                Child Details Or Member Details:
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">
                  Name : <b>Nick</b>
                </Typography>
                <Typography variant="body2">
                  Date of Birth : <b>12 feb 2023</b>
                </Typography>
                <Typography variant="body2">
                  Gender : <b>Female</b>
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={"10px"}
              >
                <Typography variant="body2">
                  Relation : <b>Son</b>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={2}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={onClickBack}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/dashboard")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="button"
                onClick={() => onStep2Submit("data")}
                title={"Confirm & Next"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                padding={7}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Client Details Section */}
            <Typography variant="body1">Case History</Typography>
            <Box sx={styles.formContainer}>
              <Box sx={styles.fieldContainer}>
                <Typography sx={styles.label1}>Chief Complaint </Typography>
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <Controller
                      name={"beneficiaryName"}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <CustomSelectPicker
                          {...field}
                          error={!!error}
                          helperText={error ? error.message : ""}
                          size={"small"}
                          fullWidth={true}
                          width={"100%"}
                          selectData={[
                            {
                              id: "1",
                              name: "Member1",
                            },
                            {
                              id: "2",
                              name: "Member2",
                            },
                          ]}
                          label={"Select Beneficiary"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Box sx={{ marginLeft: 4 }}>
                      <Controller
                        name="chiefComplaints"
                        control={control}
                        render={({
                          field: { ref, onChange },
                          fieldState: { error },
                        }) => (
                          <CustomAutocomplete
                            value={
                              getValues("chiefComplaints")
                                ? getValues("chiefComplaints")
                                : []
                            }
                            key={"chiefComplaints"}
                            error={!!error}
                            helperText={error ? error.message : ""}
                            multiple
                            ref={ref}
                            addText
                            onChange={(
                              event: React.SyntheticEvent,
                              value: any
                            ) => onChange(value)}
                            options={chiefComplaintsData}
                            label="Chief Complaint"
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={styles.fieldContainer}>
                <Grid
                  container
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  spacing={4}
                >
                  <Grid item md={6}>
                    <Grid item md={12}>
                      <Typography sx={styles.label1}>History</Typography>
                    </Grid>
                    <Box>
                      <Grid
                        container
                        justifyContent={"space-evenly"}
                        alignItems={"center"}
                        rowSpacing={2}
                        spacing={4}
                      >
                        <Grid item md={12}>
                          <Controller
                            name="presentIllness"
                            control={control}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                // field={field}
                                value={
                                  getValues("presentIllness")
                                    ? getValues("presentIllness")
                                    : []
                                }
                                key={"presentIllness"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={ILLNESS}
                                label="Present illness"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Controller
                            name="pastIllness"
                            control={control}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                // field={field}
                                value={
                                  getValues("pastIllness")
                                    ? getValues("pastIllness")
                                    : []
                                }
                                key={"pastIllness"}
                                error={!!error}
                                helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={ILLNESS}
                                label="Past illness"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Controller
                            name="familyHistoryIllness"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("familyHistoryIllness")
                                    ? getValues("familyHistoryIllness")
                                    : []
                                }
                                key={"familyHistoryIllness"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={FAMILY_ILLNESS}
                                label="Family illness"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Controller
                            name="drugHistory"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("drugHistory")
                                    ? getValues("drugHistory")
                                    : []
                                }
                                key={"drugHistory"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={DRUG_HISTORY}
                                label="Drug History"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Controller
                            name="medication"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("medication")
                                    ? getValues("medication")
                                    : []
                                }
                                key={"medication"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={USING_MEDICATIONS}
                                label="Using any Medication"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={6}>
                    <Grid
                      container
                      justifyContent={"space-evenly"}
                      alignItems={"center"}
                      rowSpacing={3}
                    >
                      <Grid item md={12}>
                        <Typography sx={styles.label}>Allergies</Typography>
                        <Box sx={styles.fieldContainer1}>
                          <Controller
                            name="allergies"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("allergies")
                                    ? getValues("allergies")
                                    : []
                                }
                                key={"allergies"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={ALLERGIES}
                                label="Select Allergies"
                              />
                            )}
                          />
                        </Box>
                      </Grid>

                      <Grid item md={12}>
                        <Typography sx={styles.label}>
                          Medical Information
                        </Typography>
                        <Box sx={styles.fieldContainer1}>
                          <Controller
                            name="medicalInformation"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("medicalInformation")
                                    ? getValues("medicalInformation")
                                    : []
                                }
                                key={"medicalInformation"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={MEDICAL_INFORMATION}
                                label={"Medical information"}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={12}>
                        <Typography sx={styles.label}>Habits</Typography>
                        <Box sx={styles.fieldContainer1}>
                          <Controller
                            name="habits"
                            control={control}
                            // rules={{ required: true }}
                            render={({
                              field: { ref, onChange },
                              fieldState: { error },
                            }) => (
                              <CustomAutocomplete
                                value={
                                  getValues("habits") ? getValues("habits") : []
                                }
                                key={"habits"}
                                // error={!!error}
                                // helperText={error ? error.message : ""}
                                ref={ref}
                                multiple
                                addText
                                onChange={(
                                  event: React.SyntheticEvent,
                                  value: any
                                ) => onChange(value)}
                                options={HABITS}
                                label="Select Habits"
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={styles.fieldContainer}>
                <Grid container flexDirection={"row"} spacing={1}>
                  <Grid item md={12}>
                    <Typography sx={styles.label1}>
                      Previous Dental Treatments
                    </Typography>
                  </Grid>
                </Grid>
                {/* Communication Details Section */}
                <Grid container flexDirection={"column"} spacing={2}>
                  {(previousTreatments || [])?.map(
                    (previous: any, index: any) => {
                      return (
                        <>
                          <Grid item mb={3}>
                            <Grid container flexDirection={"row"} spacing={1}>
                              <Grid item xs={6} md={5}>
                                <Controller
                                  name={`previousTreatments.${index}.treatmentName`}
                                  control={control}
                                  render={({
                                    field,
                                    fieldState: { error },
                                  }) => (
                                    <CustomInput
                                      {...field}
                                      error={!!error}
                                      helperText={error ? error.message : ""}
                                      label={"Enter Treatment Name"}
                                      size={"small"}
                                    />
                                  )}
                                />
                              </Grid>

                              <Grid item xs={6} md={5}>
                                <Controller
                                  name={`previousTreatments.${index}.treatmentMonthYear`}
                                  control={control}
                                  render={({
                                    field: { ref, ...field },
                                    fieldState: { error },
                                  }) => (
                                    <CustomDatePicker
                                      {...field}
                                      error={!!error}
                                      helperText={error ? error.message : ""}
                                      maxDate={new Date()}
                                      fullWidth={"fullWidth"}
                                      label={"Select Treatment Month and Year"}
                                      onChange={(event: any) => {
                                        // field.onChange(
                                        //   event ? getYearMonth(event.$d) : null
                                        // );
                                        field.onChange(event);
                                      }}
                                      views={["month", "year"]}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item md={2}>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Box sx={styles.iconContainer}>
                                    <AddOutlinedIcon
                                      sx={styles.addIcon}
                                      onClick={() => addFamilyMembers()}
                                    />
                                  </Box>
                                  {index > 0 && (
                                    <Box sx={styles.iconContainer}>
                                      <RemoveOutlinedIcon
                                        sx={styles.addIcon}
                                        onClick={() =>
                                          removeFamilyMembers(index)
                                        }
                                      />
                                    </Box>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      );
                    }
                  )}
                </Grid>

                <Typography sx={styles.label1}>Documents</Typography>

                <Box mt={2}>
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    multiple
                    classes="drop_area drop_zone"
                  />
                </Box>
                <Grid container spacing={2} gap={6}>
                  {medicalRecords?.length ? (
                    medicalRecords?.map((photo: any, index: number) => (
                      <Grid item width={"8%"} mt={3.5}>
                        <Box sx={{ position: "relative" }}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "0",
                              top: "-8px",
                              width: "30px",
                              fontSize: "12px",
                              height: "25px",
                              backgroundColor: "#e1e1e2",
                              borderRadius: "50%",
                              zIndex: "1",
                              left: "70px",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              removeImage(index);
                            }}
                          >
                            x
                          </Box>

                          <Box>
                            <ProfilePic
                              profilePhoto={
                                photo.photoPath
                                  ? photo.photoPath
                                  : photo.documentPath
                              }
                              styles={{
                                width: "90px",
                                height: "80px",
                                cursor: "pointer",
                              }}
                              isProfilePic={false}
                              onClick={() => {
                                // setSelectedImageView({
                                //   path: photo.photoPath,
                                //   name: "image.jpeg",
                                // });
                                // setTriggerModal(true);
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    ))
                  ) : (
                    <>{"No Images are available"}</>
                  )}
                </Grid>
              </Box>
            </Box>

            <Grid
              container
              flexDirection={"row"}
              spacing={0.5}
              justifyContent={"end"}
              mt={2}
            >
              {onClickBack && (
                <Grid item>
                  <CustomButton
                    border="1px solid #000000"
                    color="#000000"
                    title="Back"
                    bgColor="#ffffff"
                    handleButtonClick={onClickBack}
                    borderRadius="5px"
                  />
                </Grid>
              )}

              <Grid item>
                <CustomButton
                  border="1px solid #000000"
                  color="#000000"
                  title="Cancel"
                  bgColor="#ffffff"
                  handleButtonClick={() => {
                    if (onStep2Submit) {
                      navigate("/dashboard");
                    } else {
                      props.onClose();
                    }
                  }}
                  borderRadius="5px"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  title={"Clear"}
                  handleButtonClick={() => reset({})}
                  border="1px solid #000000"
                  color="#000000"
                  bgColor="#ffffff"
                />
              </Grid>
              <Grid item>
                <CustomButton
                  type="submit"
                  title={"Next"}
                  backgroundcolor={"#204289"}
                  bordercolor={"#204289"}
                  color={"#fffff"}
                  width={110}
                  padding={7}
                />
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  );
};

export default CaseHistoryForm;
