import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import ClientProfileCard from "../../components/ClientProfileCard";
import Breadcrumb from "../../components/Breadcrumb";
import IRATypography from "../../components/ira-typography/ira-typography";
import { apiRequest } from "../../app/services/requests";
import ClientDetailsCounter from "../Client/client-details-counter";
import PlanTopCard from "./components/plan-top-card";

const SelectPlan = () => {
  const { clientId, currentTab } = useParams();

  const [clientDetails, setClientDetails] = useState("") as any;

  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = () => {
    apiRequest({
      url: `client/${clientId}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          setClientDetails(res);
        }
      })
      .catch((err) => {
        // if (err?.statusCode === 403 || err?.statusCode === 401) {
        //   if (navigate) {
        //     navigate("/login"); // Navigate to "/login" route
        //   }
        // }
      });
  };

  let data = [
    {
      id: 1,
      heading: "Plans",
      remainingdays: "22",
      color: "#219FFF4D",
    },
    {
      id: 2,
      heading: "Claims",
      claimsCount: "05",
      color: "#68D4B44D",
    },
    {
      id: 3,
      heading: "Cases",
      claimsCount: "08",
      color: "#FF98A54D",
    },
  ];

  const navigate = useNavigate();

  const breadcrumbs = [
    <RouterLink to="/Dashboard" style={{ textDecoration: "none" }} key="1001">
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Home
      </IRATypography>
    </RouterLink>,
    <RouterLink
      to={`/client/${clientId}/view/plan-details`}
      style={{ textDecoration: "none" }}
      key="1001"
    >
      <IRATypography
        key="2"
        textVariant="h12WithSemiBold"
        sx={{ fontSize: 14, color: "#222222" }}
      >
        Client Profile
      </IRATypography>
    </RouterLink>,
    <IRATypography
      key="3"
      textVariant="h12WithSemiBold"
      sx={{ fontSize: 14, color: "#222222" }}
    >
      Select Plan
    </IRATypography>,
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: "0px !important" }}>
      <Breadcrumb
        title={`Client Profile`}
        key="case-list-breadcrumb"
        breadcrumbsArr={breadcrumbs}
      />
      <Box>
        <Grid container spacing={2}>
          {/* 3 columns */}
          <Grid item xs={3.5} mt={1}>
            <Box>
              <ClientProfileCard clientDetails={clientDetails} />
            </Box>
          </Grid>
          {/* 9 columns */}

          <Grid item xs={8.5} mt={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              mb={2.5}
            >
              {data?.map((item) => {
                return <ClientDetailsCounter CounterData={item} />;
              })}
            </Box>
            <Paper>
              {/* Content for the second column */}
              <Box mb={2}>
                <PlanTopCard
                  planType={"standard"}
                  clientId={clientId}
                  onClickable={true}
                />
              </Box>
              <Box mb={2}>
                <PlanTopCard
                  planType={"discount"}
                  clientId={clientId}
                  onClickable={true}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default SelectPlan;
