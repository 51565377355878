import React from "react";
import CustomCard from "../../../components/Card";
import { Box, Grid, Typography } from "@mui/material";
import healthPlanCardSvg from "../../../app/assets/DentaAssurePlans/health-plan-card.png";
import { theme } from "../../../theme/theme";
import { useNavigate } from "react-router-dom";
const PlanTopCard = (props: any) => {
  const { planType, clientId, onClickable = false } = props;
  const navigate = useNavigate();

  const navigateTo = () =>
    onClickable && navigate(`/client/${clientId}/plan/${planType}/1`);
  return (
    <>
      <CustomCard
        sx={{
          height: "145px",
          ...(onClickable && {
            cursor: "pointer",
            "&:hover": {
              // backgroundColor: "red", // Example hover style
              // color: "white", // Change text color on hover
              transition: "0.3s", // Smooth transition effect
              border: "2px solid #d4d2fe",
            },
          }),
        }}
        onClick={navigateTo}
      >
        <Grid container spacing={2} p={0}>
          <Grid item xs={4}>
            <img src={healthPlanCardSvg} alt="" />
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Box mt={"15px"}>
                <Typography
                  color={theme.palette.customColors.main}
                  variant="h6"
                  fontWeight={"600"}
                >
                  {planType === "discount" ? "Discount Plan" : "Standard Plan"}
                </Typography>
              </Box>
              <Typography variant="body1" mt={"6px"} fontSize={"14px"}>
                {`It is a long established when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box p={2}></Box>
      </CustomCard>
    </>
  );
};

export default PlanTopCard;
