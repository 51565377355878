import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/Button";
import FamilyMemberList from "../family-member-list";

const Step2 = (props: any) => {
  const {
    onStep2Submit,
    showSkip,
    onClickBack,
    handleCancel,
    appointmentId,
    treatments,
    selectedPlanType,
  } = props;

  const navigate = useNavigate();
  return (
    <Box p={2}>
      {/* Your Basic Member Details Form or Component */}
      {selectedPlanType === "simple" ? (
        <>
          <Box>{"No Case History required Confirm your plan details"}</Box>
          <Box>{"Plan Details Comes here"}</Box>
          <Grid
            container
            flexDirection={"row"}
            spacing={0.5}
            justifyContent={"end"}
            mt={2}
          >
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Back"
                bgColor="#ffffff"
                handleButtonClick={onClickBack}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                border="1px solid #000000"
                color="#000000"
                title="Cancel"
                bgColor="#ffffff"
                handleButtonClick={() => navigate("/users/all")}
                borderRadius="5px"
              />
            </Grid>
            <Grid item>
              <CustomButton
                type="button"
                onClick={() => onStep2Submit("data")}
                title={"Confirm & Next"}
                backgroundcolor={"#204289"}
                bordercolor={"#204289"}
                color={"#fffff"}
                padding={7}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <FamilyMemberList />
        </>
      )}
    </Box>
  );
};

export default Step2;
