import { Box } from "@mui/material";
import ActionCell from "../../../components/TableComponent/action-cell";
import DateTimeCell from "../../../components/TableComponent/date-time-cell";
import NameCell from "../../../components/TableComponent/name-cell";
import { IRATable } from "../../../components/ira-table/ira-table";
import { IRATableColumnProps } from "../../../components/ira-table/ira-table.interfaces";
import CustomButton from "../../../components/Button";
import { useEffect, useState } from "react";
import CustomDialog from "../../../components/CustomDialog";
import AddEditEquipment from "./add-edit-equipment";
import { apiRequest } from "../../../app/services/requests";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const Equipments = () => {
  const [openSelectClientDialog, setOpenSelectClientDialog] = useState<any>({
    open: false,
    data: {},
  });
  const [equipmentData, setEquipmentData] = useState("") as any;

  const [currentEquipmentDetails, setCurrentEquipmentDetails] = useState<any>({
    data: {},
    open: false,
    idEdit: false,
  });
  // console.log(equipmentData);
  const navigate = useNavigate();
  const { hospitalId } = useParams();

  useEffect(() => {
    getAllEquipments();
  }, []);

  const getAllEquipments = () => {
    apiRequest({
      url: `clinic/${hospitalId}/equipment/list`,
      data: {
        // userType: "superAdmin",
      },

      method: "POST",
    })
      .then((res) => {
        if (res) {
          setEquipmentData(res);
        }
      })
      .catch((err) => {
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          if (navigate) {
            navigate("/login"); // Navigate to "/login" route
          }
        }
      });
  };

  const activityTableColumns = () =>
    [
      {
        name: "equipmentId",
        label: "Equipment Id",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "equipmentType",
        label: "Equipment Type",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "details",
        label: "Equipment Details",
        component: NameCell,
        sortable: false,
        style: { width: "350px" },
      },

      {
        name: "createdAt",
        label: "Added Date",
        component: DateTimeCell,
        sortable: false,
        style: { width: "350px" },
      },
      {
        name: "editAction",
        label: "",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Edit",
            onClick: () => {
              setOpenSelectClientDialog({ open: true, data: props?.data });
              setCurrentEquipmentDetails({
                open: true,
                data: props?.data,
                isEdit: true,
              });
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
      {
        name: "action",
        label: "Action",
        component: (props: any) => {
          return ActionCell({
            button: true,
            btnVariant: "primary",
            btnSize: "smallest",
            disabled: false,
            infoMessage: null,
            label: "Remove",
            onClick: () => {
              alert("comes here");
            },
            ...props,
          } as any); // <-- This 'as any' is not needed and might be causing the issue
        },
      } as any,
    ] as Array<IRATableColumnProps>;

  const activityTableData = () => {
    return equipmentData?.data?.map((row: any) => {
      return {
        ...row,
        equipmentId: {
          name: row?.id,
        },
        equipmentType: {
          name: row?.equipmentType,
        },
        details: {
          name: row?.equipmentDetails,
        },
        createdAt: {
          dateTime: moment(row.createdAt).format("DD-MM-YYYY"),
        },
        editAction: {
          data: {
            ...row,
          },
        },
        action: {
          data: {
            id: row?.id,
            status: "reviewed",
            project: {
              id: row?.id,
            },
          },
        },
      };
    });
  };

  const handleButtonClick = () => {
    setOpenSelectClientDialog({ open: true });
  };

  return (
    <div>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "95%",
          marginLeft: "3%",
        }}
        mr={2}
      >
        {/* <Breadcrumb title={`Appointments`} breadcrumbsArr={breadcrumbs} /> */}

        <Box
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            // gap: "8px",
          }}
        >
          <CustomButton
            bgColor={"rgba(92, 78, 255, 1)"}
            onClick={() =>
              setCurrentEquipmentDetails({
                open: true,
                isEdit: false,
                data: {},
              })
            }
            title={"Add Equipment"}
          />
        </Box>
      </Box>
      <Box p={2}>
        <IRATable
          columns={activityTableColumns()}
          data={activityTableData()}
          onSort={(name: string, order: string) =>
            alert(`sort requested for ${name} with order ${order}`)
          }
        />
      </Box>

      {currentEquipmentDetails.open && (
        <CustomDialog
          width={"650px"}
          open={currentEquipmentDetails.open}
          handleClose={() =>
            setCurrentEquipmentDetails({ open: false, data: {} })
          }
          title={
            currentEquipmentDetails?.isEdit === true
              ? "Edit Infrastructure"
              : "Add Infrastructure"
          }
          maxWidth={"lg"}
        >
          <Box>
            <AddEditEquipment
              getAllEquipments={() => getAllEquipments()}
              onClose={() =>
                setCurrentEquipmentDetails({ open: false, data: {} })
              }
              isEdit={currentEquipmentDetails?.isEdit}
              currentEquipment={currentEquipmentDetails.data}
              clinicId={hospitalId}
            />
          </Box>
        </CustomDialog>
      )}
    </div>
  );
};

export default Equipments;
